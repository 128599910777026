import printJS from 'print-js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, DateFormats, formatDate, getFiveDigitsZipCode, getNetworkTagType, getTicketListingTranslationKey, getTwoDigitsCountryCode, getUnitDisplaySettings, getUpdatedPhoneNumber, handleAmountFormattingString, handleDecimalValuesForDisplay, handleDisplayUnitName, isAndroidDevice, isFirefoxBrowser, isSafariBrowser } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import type { ListingDetails } from '../../../modules/partnership';
import CardAsset from '../../../resources/legacyIcons/CreditCardBlack.svg';
import type { IconTextDescriptionCombinedProps } from '../../molecules/IconTextDescription';
import { MLB_INTEGRATION_ID, type MarketingOptinMlbProps } from '../../molecules/MarketingOptinMlb';
import type { PaymentMethodCombinedProps } from '../../molecules/PaymentMethod/types';
import type { TextItemCombinedProps } from '../../molecules/TextItem/types';
import { formatter, formatTitle, getSeatInfoFromTicket } from '../CheckoutManualPage/CheckoutManualPage.utils';
import useInteractor from './ReceiptPage.interactor';
import styles from './ReceiptPage.module.scss';
import type { ReceiptPageCombinedProps } from './types';
import type { IconDescription } from './utils';

const usePresenter = (props: ReceiptPageCombinedProps): ReceiptPageCombinedProps => {
  const { account, orderItems } = useInteractor(props);
  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      'Order Details/Confirmation Page',
    );
  }, []);

  const [pdfurl, setUrl] = useState('');
  const shouldDownloadPDF = isAndroidDevice() || isSafariBrowser() || isFirefoxBrowser();
  const showCharge = (orderItems?.dollarsUsed || 0) > 0;
  const showRewards = (orderItems?.rewardsUsed || 0) > 0;
  const rewardType =
    account?.loyalty_program?.loyalty_unit_name.toLowerCase();
  const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);

  const confirmationPagePath: string = getTicketListingTranslationKey(
    orderItems?.listing as ListingDetails,
    'confirmation_page',
  );

  let isGuest = false;
  if (confirmationPagePath === 'precheckoutBlock.guestList') {
    isGuest = true;
  }

  const confirmationPageOptions: string[] = t(`${confirmationPagePath}`, {
    customerEmail: account?.email,
    customerMobilePhone: account?.phone,
    customerShippingAddress: t(
      'deliveryInformation.ticketDelivery.customerAddressTemplate',
      {
        address: account?.address.address_line1,
        city: account?.address.city,
        state: account?.address.state_code,
        postalCode: account
          ? getFiveDigitsZipCode(
            account.address.country_code,
            account.address.postal_code,
          )
          : '',
        country: getTwoDigitsCountryCode(account?.address.country_code || ''),
      },
    ),
    returnObjects: true,
  });

  const iconDescriptions: IconDescription[] = !isGuest
    ? Array.isArray(confirmationPageOptions)
      ? confirmationPageOptions.map((option) => {
        return {
          asset: '',
          description: `• ${option}`,
        };
      })
      : []
    : [
      {
        asset: '',
        description: `${t(
          'orderConfirmation.guestListEventOrderNotes.title',
        )}`,
      },
      {
        asset: '',
        description: `• ${t(
          'orderConfirmation.guestListEventOrderNotes.email',
          {
            email: account?.email,
          },
        )}`,
      },
      {
        asset: '',
        description: `• ${t(
          'orderConfirmation.guestListEventOrderNotes.name',
        )}`,
      },
      {
        asset: '',
        description: `• ${t(
          'orderConfirmation.guestListEventOrderNotes.onSiteEvent',
        )}`,
      },
      {
        asset: '',
        description: `• ${t(
          'orderConfirmation.guestListEventOrderNotes.virtualEvent',
        )}`,
      },
    ];

  if (!isGuest) {
    iconDescriptions.unshift({
      asset: '',
      description: t(
        getTicketListingTranslationKey(
          orderItems?.listing as ListingDetails,
          'delivery_header',
        ),
      ),
    });
  }

  const paymentMethod: PaymentMethodCombinedProps = {
    cardImage: {
      imageSrc: account?.loyalty_program?.program_logo_url,
      imageFallback: CardAsset,
    },
    label: {
      value: showCharge
        ? t('billingInfo.paymentMethod')
        : t('billingInfo.rewardsRedeemed'),
    },
    description: {
      value: showCharge
        ? capitalize(getNetworkTagType(account))
        : t('billingInfo.rewardsTemplate', {
          value:
            orderItems?.rewardsUsed &&
            `${rewardSign}${handleDecimalValuesForDisplay(
              handleAmountFormattingString(orderItems?.rewardsUsed),
              useDecimals,
            )}`,
          unit: handleDisplayUnitName(account),
        }),
    },
    showCard: showCharge,
  };
  const totalCardPayment: TextItemCombinedProps = {
    label: {
      value: showCharge ? t('billingInfo.totalCardPayment') : '',
    },
    description: {
      value: showCharge ? formatter.format(orderItems?.dollarsUsed || 0) : '',
    },
  };

  const rewardsMilesRedeemed: TextItemCombinedProps = {
    label: {
      value:
        showRewards && showCharge ? t('billingInfo.rewardsRedeemed') : '',
    },
    description: {
      value:
        showRewards && showCharge
          ? t('billingInfo.rewardsTemplate', {
            value:
              orderItems?.rewardsUsed &&
              `${rewardSign}${handleDecimalValuesForDisplay(
                handleAmountFormattingString(orderItems?.rewardsUsed),
                useDecimals,
              )}`,
            unit: handleDisplayUnitName(account),
          })
          : '',
    },
  };

  const seatNumbers: string = useMemo(() => getSeatInfoFromTicket(orderItems?.listing), [orderItems?.listing]);

  const marketingOptinMlbProps: MarketingOptinMlbProps | undefined = useMemo(
    () => {
      if (!orderItems?.order) {
        return undefined;
      }

      const shouldShowMarketingOptinMlb: boolean = !!orderItems.order.has_integrated_inventory
        && orderItems.order.inventory_integration_details?.integration_id === MLB_INTEGRATION_ID;

      if (!shouldShowMarketingOptinMlb) {
        return undefined;
      }

      const marketingOptinMlbTeamName: string | undefined = orderItems.order.event?.performers?.find(({ is_primary }) => is_primary)?.name;

      return {
        orderId: orderItems.order.id,
        teamName: marketingOptinMlbTeamName,
      };
    },
    [orderItems?.order],
  );

  return {
    ...props,
    receiptBlock: {
      title: {
        value: t('orderConfirmation.title'),
      },
      orderDetails: {
        infoHeader: {
          title: {
            value: t('orderConfirmation.header'),
          },
          details: {
            value: t('orderConfirmation.detailsTemplate', {
              ticketId: orderItems?.order?.id,
            }),
          },
        },
        ticketInfoTitle: {
          value: t('orderConfirmation.ticketInfoTitle'),
        },
        showHighlight: false,
        showCloseButton: false,
        printButton: {
          icon: {
            asset: 'Printer',
          },
          text: {
            value: t('orderConfirmation.print'),
          },
          onClick: () => {
            trackSelectContentEvent(
              trackEvent,
              'Order Details Page',
              'Order Detials',
              t('orderConfirmation.print'),
            );
            if (pdfurl) {
              // for android devices the blob pdf url will not open a print dialog on the same browser tab,
              // also, for safari and firefox browsers, print button shows empty as that's a known issue,
              // in order to make it work
              // we are downloading the pdf file so user can open that and print using native print dialog
              if (shouldDownloadPDF) {
                const a = document.createElement('a');
                a.href = pdfurl;
                a.download = `VividSeats_receipt_${orderItems?.order?.event?.id}`;
                a.style.position = 'fixed';
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              } else { // for rest of the platforms (desktop, ios)
                printJS(pdfurl);
              }
            }
          },
        },
        infoDetails: {
          title: {
            value: orderItems?.order?.event?.name,
          },
          eventDateStatus: {
            date: {

              value: orderItems?.order?.event?.local_date
                ? formatDate(
                  new Date(orderItems?.order?.event?.local_date),
                  t,
                  DateFormats.TICKETINFO,
                )
                : '',
            },
          },
          venue: {
            value: formatTitle(
              orderItems?.order?.event?.venue.name || '',
              orderItems?.order?.event?.venue.city || '',
              orderItems?.order?.event?.venue.state_code || '',
            ),
          },
        },
        seatInfo: {
          section: {
            label: {
              value: t('ticketInfo.seatInfo.section'),
            },
            description: {

              value: orderItems?.order?.ticket?.section,
            },
          },
          row: {
            label: {
              value: t('ticketInfo.seatInfo.row'),
            },
            description: {
              value: orderItems?.order?.ticket?.row,
            },
          },
          quantity: {
            label: {

              value: t('ticketInfo.seatInfo.quantity'),
            },
            description: {

              value: orderItems?.order?.ticket?.quantity?.toString(),
            },
          },
          seats: seatNumbers ? {
            label: {
              value: t('ticketInfo.seatInfo.seats'),
            },
            description: {
              value: seatNumbers,
            },
          } : undefined,
        },
        sellerNotes: {
          label: {
            value: orderItems?.order?.ticket?.notes
              ? t('ticketInfo.sellerNotes')
              : '',
          },
          description: {

            value: orderItems?.order?.ticket?.notes,
          },
        },
        showLogo: true,
        logo: {
          asset: orderItems?.isCp1Exclusive ? 'LogoC1Default' : 'VividLogo',
        },
        divider: {
        },
        deliveryInfoTitle: {
          value: t('orderConfirmation.deliveryInfoTitle'),
        },
        additionalTicketInformationList: {

          iconTextDescriptions: iconDescriptions.map((iconDescription) => {
            return {
              icon: iconDescription.asset
                ? {
                  asset: iconDescription.asset,
                }
                : {
                  className: styles.hideIcons,
                },
              description: {
                value: iconDescription.description,
              },
              type: 'Default',
            } as IconTextDescriptionCombinedProps;
          }),
        },
        deliveryDateInfo: {
          description: {
            value: orderItems?.order?.ticket?.fulfillment
              ?.estimated_delivery_date
              ? formatDate(
                new Date(
                  orderItems?.order?.ticket?.fulfillment?.estimated_delivery_date,
                ),
                t,
                DateFormats.CONFIRMATION,
              )
              : '',
          },
          label: {
            value: t('orderConfirmation.expectedDelivery'),
          },
        },
        billingInfoTitle: {
          value: t('orderConfirmation.billingInfoTitle'),
        },
        infoOnMultipleLine: {
          label: {
            value: t('billingInfo.title'),
          },
          address: {
            value: t('billingInfo.smallVersionTemplate', {
              firstName: account?.first_name,
              lastName: account?.last_name,
              address: account?.address.address_line1,
              city: account?.address.city,
              state: account?.address.state_code,
              postalCode: account
                ? getFiveDigitsZipCode(
                  account.address.country_code,
                  account.address.postal_code,
                )
                : '',
              country: getTwoDigitsCountryCode(
                account?.address.country_code || '',
              ),
            }),
          },
        },
        phoneNumber: {
          label: {
            value: t('billingInfo.phoneNumber'),
          },
          description: {
            value: getUpdatedPhoneNumber() || account?.phone,
          },
        },
        paymentMethod,
        totalCardPayment,
        rewardsMilesRedeemed,
        rightContent: rewardsMilesRedeemed,
        marketingOptinMlbProps,
      },
      isCp1Exclusive: orderItems?.isCp1Exclusive,
      cardHolderExclusiveLabel: t('orderConfirmation.cardHolderLabel'),
      ticketNotice: t('orderConfirmation.ticketNotice'),
      isMobileTicket: true, // Discard once VSCO-433 is implemented
    },
    setUrl,
  };
};

export default usePresenter;
