import { formatDateToApiDate, formatDateToMonthDay, isValidDate } from '../../../lib/util';
import { DayOfWeek, getCertainDay, today, tomorrow } from '../../../modules/date/utils';
import i18n from '../../../modules/locale/i18n';
import type { Region } from '../../../modules/partnership/types';
import type { DropdownOption } from './types';

export const dateFilterOptions: DropdownOption[] = [
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.any'),
    value: {},
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.today'),
    value: {
      startDate: today(),
      endDate: today(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.tomorrow'),
    value: {
      startDate: tomorrow(),
      endDate: tomorrow(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.weekend'),
    value: {
      startDate: getCertainDay(DayOfWeek.Friday),
      endDate: getCertainDay(DayOfWeek.Sunday),
    },
  },
  {
    type: 'PickDate',
    title: i18n.t('dateFilters.chooseDate'),
  },
];

export const exclusiveDateFilterOptions: DropdownOption[] = [
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.any'),
    value: { startDate: today() },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.today'),
    value: {
      startDate: today(),
      endDate: today(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.tomorrow'),
    value: {
      startDate: tomorrow(),
      endDate: tomorrow(),
    },
  },
  {
    type: 'SelectValue',
    title: i18n.t('dateFilters.weekend'),
    value: {
      startDate: getCertainDay(DayOfWeek.Friday),
      endDate: getCertainDay(DayOfWeek.Sunday),
    },
  },
  {
    type: 'PickDate',
    title: i18n.t('dateFilters.chooseDate'),
  },
];

export const formatStartEndDateOption = (startDate?: Date, endDate?: Date): string => {
  if (startDate && isValidDate(startDate) && endDate && isValidDate(endDate)) {
    return `${formatDateToMonthDay(startDate)} - ${formatDateToMonthDay(endDate)}`;
  }
  return ' - ';
};

export const getDefaultFilterOption = (options: DropdownOption[]): DropdownOption | undefined => {
  return options.find((option) => option.type !== 'SearchInput') || options[0];
};

export const getSelectedLocationFilterOption = (locations: Region[] | undefined, regionId: string | null): DropdownOption | undefined => {
  if (!locations?.length || !regionId) {
    return undefined;
  }

  const location: Region | undefined = locations.find((region: Region) => region.id.toString() === regionId);
  if (location) {
    return {
      title: `${location.city}, ${location.state_code}`,
      type: 'SelectValue',
      value: {
        value: location.id.toString(),
      },
    };
  }

  return undefined;
};

export const getDefaultLocationFilterOptions = (currentRegion: Region | undefined, includeAllLocationsOption?: boolean): DropdownOption[] => {
  const defaultLocationFilterOptions: DropdownOption[] = [];

  if (includeAllLocationsOption) {
    defaultLocationFilterOptions.push({
      type: 'SelectValue',
      title: i18n.t('regionFilters.allLocations'),
      value: {},
    });
  }

  defaultLocationFilterOptions.push({
    type: 'SearchInput',
    title: i18n.t('regionFilters.searchLocation'),
    value: {},
  });

  if (currentRegion) {
    defaultLocationFilterOptions.push({
      type: 'SelectValue',
      title: `${currentRegion.city}, ${currentRegion.state_code}`,
      dropdownTitle: i18n.t('regionFilters.currentLocation'),
      value: {
        value: currentRegion.id.toString(),
      },
    });
  }

  return defaultLocationFilterOptions;
};

export const getFilteredLocationFilterOptions = (regions: Region[], filterText: string): DropdownOption[] => {
  const lowerCasedFilterText: string = filterText.toLowerCase();

  return regions
    .filter(({ city, state }: Region) => city.toLowerCase().includes(lowerCasedFilterText) || state.toLowerCase().includes(lowerCasedFilterText))
    .map((region: Region) => ({
      type: 'SearchResult',
      title: `${region.city}, ${region.state_code}`,
      value: {
        value: region.id.toString(),
      },
    }));
};

export const defaultDateToTodayIfEmpty = (date_start?: string): string => {
  if (date_start === undefined || !date_start) {
    return formatDateToApiDate(today());
  }
  return date_start;
};
